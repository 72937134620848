import React from 'react';

export const DataContext = React.createContext({
  location: null,
  pageContext: null
});

export function DataProvider({ children, value }) {
  const { location, pageContext } = value;

  return (
    <DataContext.Provider value={{ location, pageContext }}>
      {children}
    </DataContext.Provider>
  );
}
